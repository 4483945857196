.login {
  width: 100vw;
  height: 100vh;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 70%;
  height: 70%;
  display: flex;
}

.loadfileLeft
{
  margin-right: 20px;
  border: 2px solid grey;
  height: 400px;
  padding: 20px;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: top;
  border-radius: 10px;
}

.loadfileRight {
  padding: 40px;
  border: 2px solid grey;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.rightdes {
  font-size: 50px;
  font-weight: 800;
  color: #0e3a74;
  margin-bottom: 10px;
}

.Desc {
  font-size: 24px;
}

.loginBoxx{
  height: 170px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginInputt{
  height: 50px;
  
 
  font-size: 18px;
  padding-left: 0px;
}


.loginInput:focus{
    outline: none;
}

.loginButtonn{
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #0e3a74;
    ;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.loginForgot{
    text-align: center;
    color: #0e3a74;
}

.loginRegisterButton{
    width: 60%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #12360b;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}