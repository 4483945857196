.sidebarUserName {
    display: flex;
    align-items: center;
    margin-right: 10px
  }

  .sidebarUser {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 10px
  }
  
  .sidebarFriendImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  .deleteIcon{
    width: 24px;
    height: 24px;
    margin-right: 5px;
    cursor: pointer;
    color: darkgray
}

.userIcon{
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
  color: darkgray
}