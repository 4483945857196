.updatechatbot {
  width: 100vw;
  height: 100vh;
  background-color: #1e1e1e;
  color: #03A062;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updatechatbotWrapper {
  width: 70%;
  height: 70%;
  display: flex;

}

.updatechatbotLeft
{
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #98FF98;
  margin-right: 20px;
  border-radius: 10px;
  padding: 20px;
  flex-direction: row;
}
.registerListItem
{
  flex: 1;
  margin-left: 10px;
  list-style: none;
  background-color: black;
  padding: 10px;
  height: 50px;
  border-radius: 10px;
  flex-direction: row;
  
}

.groupIcon
{
  margin-right: 5px;
}

.linkItem
{
  align-self: right;
}

.updatechatbotRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.updatechatbotLogo {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 10px;
  color:darkgray;
}

.updatechatbotDesc {
  font-size: 24px;
  color: darkGray;
}

.updatechatbotloginBox{
    height: 1200px;
    padding: 20px;
    background-color: #98FF98;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}




.updatechatbotInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
    color:darkgray;  
  background-color: black;
}

.textareaInput{
 
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  color:darkgray;  
background-color: black;
}

.updatechatbotcheckboxLabel{
  
  align-items: center;
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;;
  color: grey;
  margin-right: 10px 
}

.updatechatbotcheckmark {
  margin-left: 10px; /* adjust as needed */
  margin-right: 10px; /* adjust as needed */
  width: 20px;
  height: 20px;
  color: black;
  accent-color: rgb(44, 67, 3);
}

.mytextarea { 
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  width: 600px;
  height: 100px;
}

.loginInput:focus{
    outline: none;
}

.updatechatbotloginButton{
    height: 50px;
    margin-top: 5px;
    border-radius: 10px;
    border: none;
    background-color: rgb(44, 67, 3);
    ;
    color: darkgray;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.loginForgot{
    text-align: center;
    color: #0e3a74;
}

.loginUpdateButton{
    width: 60%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: rgb(44, 67, 3);
    color: darkGray;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 5px;
}

.userList {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: black;
  padding: 10px;
  width: 300px;
  min-height: 500px;
  border-radius: 10px;
}