a {
  color: #03A062;
}

.topbarContainer {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  color:#03A062;
  background: black;
  border: 1px solid green;
}

.topbarLeft {
  flex: 5;
  display: flex;
  flex-direction: row;
}

.logo {
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
 
}

.logotext {
  font-size: 150%;
  margin-left: 20px;
  cursor: pointer;
  width: 100%
  
}

.topbarCenter {
  flex: 2;
}


.searchInput {
  border: none;
  width: 70%;
}

.searchInput:focus {
  outline: none;
}

.topbarRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.topbarLink {
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.topbarIcons {
  display: flex;
}

.topbarIconItem {
  margin-right: 15px;
  cursor: pointer;
  position: relative;
}

.topbarIconBadge {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.topbarImg {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
}


