.modalDialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
   
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 400px;
  }

  .modalDialog div {
    margin-bottom: 10px; /* Adjust the margin to control the vertical spacing */
  }
  
  

  .top {
    display: flex;
    justify-content: space-between; /* Align items on the same row */
    align-items: center; /* Vertically center items */
    border-bottom: 1px solid #03A062; /* Add a simple border line below */
  }
  
  .modal-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Align items on the same row */
    align-items: center; /* Vertically center items */
    /* Your existing styles... */
  }
  
  .modalcancelbutton {
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
    background-color: white;
    color: black;
    border-radius: 5px;
  }

  .modalUpdatebutton {
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
    background-color: black;
    color: white;
  }
  
  
  .modalDialog-buttons {
    text-align: right;
  }

  

  .KMLFileText {
    text-align: left;
  }

  .filetext {
    text-align: left;
    border: 1px solid #1B3633; 
    padding: 10px; 
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .left
  {
   font-size: 20;
   font-weight: 200;
  }

  .closex
  {
    text-align: right;
  }

  .input
  {
    margin-bottom: 10px;
    margin-top: 10px;
    width:350px;
    border-radius: 5px;
  }
  .inputname
  {
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #ccc;
    width:350px;
    border-radius: 5px;
  }

  .switch-container {
    display: flex;
    flex-direction: row;
    align-items:normal; 
    gap: 10px;
  }
  
  