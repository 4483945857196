.updateuser {
  width: 100vw;
  height: 100vh;
  background-color: #1e1e1e;
  color: #03A062;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateuserWrapper {
  width: 70%;
  height: 70%;
  display: flex;
}

.updateuserLeft,
.updateuserRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.updateuserLogo {
  font-size: 50px;
  font-weight: 800;
  color:darkgray;
  margin-bottom: 10px;
}

.updateuserDesc {
  font-size: 24px;
  color: darkGray;
}

.updateuserBox{
    height: 400px;
    padding: 20px;
    background-color:#98FF98;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.updateuserInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
    color:darkgray;  
    background-color: black;
}

.updateuserInput:focus{
    outline: none;
}

.updateuserButton{
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: rgb(44, 67, 3);
    color: darkgray;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.updateuserForgot{
    text-align: center;
    color: #0e3a74;
}

.updateuserRegisterButton{
    width: 60%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: rgb(44, 67, 3);
    color: darkgray;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}