.login {
  width: 100vw;
  height: 100vh;
  background-color: #1e1e1e;
  color: #03A062;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 70%;
  height: 70%;
  display: flex;

}

.loginLeft,
.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.loginLogo {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 10px;
  color:darkgray;
}

.loginDesc {
  font-size: 24px;
  color: darkGray;
}

.loginBox{
    height: 1200px;
    padding: 20px;
    background-color: #98FF98;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
}

.loginInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
    color:darkgray;  
  background-color: black;
}

.checkboxLabel{
  
  align-items: center;
  height: 50px;
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;;
  color: grey;
  margin-right: 10px 
}

.checkmark {
  margin-left: 10px; /* adjust as needed */
  margin-right: 10px; /* adjust as needed */
  width: 20px;
  height: 20px;
  color: black;
  accent-color: rgb(44, 67, 3);
}

.mytextarea { 
  border-radius: 10px;
  border: 1px solid gray;
  font-size: 18px;
  padding-left: 20px;
  width: 600px;
  height: 100px;
}

.loginInput:focus{
    outline: none;
}

.loginButton{
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: rgb(44, 67, 3);
    ;
    color: darkgray;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.loginForgot{
    text-align: center;
    color: #0e3a74;
}

.loginRegisterButton{
    width: 60%;
    align-self: center;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: rgb(44, 67, 3);
    color: darkGray;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}