.post {
  width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.68);
  margin: 30px 0;
  color:#03A062;
  background-color: #1e1e1e;
}

.postWrapper {
  padding: 10px;
}

.postTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.postTopLeft {
  display: flex;
  align-items: left;
}

.postTopRight {
  display: flex;
  align-items: center;
  font-weight: 400;
}

.postProfileImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.postUsername {
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
}

.postDate{
    font-size: 12px;
}

.chatbotField{
  font-size: 12px;
  margin-right: 10px;
  
}

.chatbotFieldBold{
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold; 
  color: darkgray;
}

.checkboxitem
{
  margin-right: 5px;
  accent-color: red;
}



.checkmark:disabled {
  color: red;
}

.checkbox
{
  font-weight: bold;
  accent-color: red;
}
.postCenter{
    margin: 20px 0;
}

.postImg{
    margin-top: 20px;
    width: 100%;
    max-height: 500px;
    object-fit: contain;
}

.postBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.postBottomLeft{
    display: flex;
    align-items: center;   
}

.likeIcon{
    width: 24px;
    height: 24px;
    margin-right: 5px;
    cursor: pointer;
}

.deleteIcon{
  width: 24px;
  height: 24px;
  margin-right: 5px;

  cursor: pointer;
}

.mailIcon{
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-top: 1px;
  cursor: pointer;
}

.chatIcon{
  width: 24px;
  height: 24px;
  margin-right: 15px;
  cursor: pointer;
}

.fileIcon{
  width: 24px;
  height: 24px;
  margin-right: 15px;
  cursor: pointer;
}

.postLikeCounter{
    font-size: 15px;
}

.postCommentText{
    cursor: pointer;
    border-bottom: 1px dashed gray;
    font-size: 15px;
}

.postIcon{
  width: 24px;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
  color: darkgray
}