.fileInput{
    height: 32px;
    margin-top: 25px;
    margin-right: 0px;
    border-radius: 10px;
    border: 1px solid gray;
    padding-top: 10px;
    padding-left: 10px;
    background-color: white;
    color: grey;
    font-size: 18px;

}

.urlInput{
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 18px;
    padding-left: 20px;
    margin-top: 10px
}
.uploadBox{
    height: 250px;
    padding: 20px;
    background-color: lightgrey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.uploadelements
{
/* Enable flex mode */
display: flex;
/* Align items vertically in center */
align-items:center ;
/* Add some margin */
margin: 10px;
margin-right: 20px;

}

.myupload
{
    height: 25px;
    border-radius: 10px;
    border: none;
    background-color: #0e3a74;
    ;
    color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;

margin-top: 18px;

}